import request from '@/utils/request'

// 通知详情

export function NoticeDetail(query) {
    return request({
        url: '/mobile/message/detail',
        method: 'get',
        params: query
    })
}

// 通知列表
export function NoticeIndex(query) {
    return request({
        url: '/mobile/message/index',
        method: 'get',
        params: query
    })
}
//  阅读明细
export function NoticeView(query) {
    return request({
        url: '/mobile/message/view',
        method: 'get',
        params: query
    })
}
// 编辑通知
export function NoticeEdit(data) {
    return request({
        url: '/mobile/message/edit',
        method: 'post',
        data: data
    })
}
// 添加通知
export function NoticeAdd(data) {
    return request({
        url: '/mobile/message/add',
        method: 'post',
        data: data
    })
}
//年级班级列表
export function NoticeGradeClasses(query) {
    return request({
        url: '/mobile/common/gradeClasses',
        method: 'get',
        params: query
    })
}
//班级列表
export function NoticeClasses(query) {
    return request({
        url: '/mobile/common/classes',
        method: 'get',
        params: query
    })
}

// 取消发送
export function NoticeLock(query) {
    return request({
        url: '/mobile/message/lock',
        method: 'get',
        params: query
    })
}

// 一件通知
export function Noticenotice(query) {
    return request({
        url: '/mobile/message/notice',
        method: 'get',
        params: query
    })
}


