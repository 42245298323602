<template>
    <div class="body">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false" finish-text="没有更多了"
                @load="onLoad">
                <!-- 头部导航 -->
                <div class="flexd">
                    <div class="flex-item ">
                        <div class="backcolor">
                            <span class="iconfont icon-fanhui" @click="getParents">返回</span>
                        </div>
                        <div class="backphoto" @click="getPublish">
                            <img src="../../assets//icon/fabu.png" alt="">
                        </div>
                    </div>
                    <!-- 切换 -->
                    <div class="flex-items">
                        <div class="box" :class="indexs == index ? 'boxs' : 'box'" v-for="(item, index) in list"
                            :key="index" @click="getChange(index)">
                            {{ item.text }}</div>
                        <div class="select" @click="getSelect">
                            <div class="title">{{ value }}</div>
                            <span class="iconfont icon-xiala allClasss"></span>
                        </div>
                    </div>
                </div>
                <!-- 占位符 -->
                <div style="height: 102px;">

                </div>
                <!-- 内容 -->
                <div class="contain" v-if="listDate.length > 0">
                    <div class="contain-box" @click="getDetail(item.id)" v-for="(item, index) in listDate" :key="index">
                        <div class="display margin_top">
                            <div class="left"></div>
                            <div class="text">{{ item.title }}</div>
                        </div>
                        <div class="display time_num">
                            <div class="display">
                                <div class="title">发布时间：</div>
                                <div class="time"> {{ item.create_time }}</div>
                            </div>
                            <div class="display">
                                <div class="title">已读人数：</div>
                                <div class="time">{{ item.read_count }}</div>
                            </div>
                        </div>
                        <div class="display">
                            <div class="avater">
                                <img src="../../assets/img/avatar.png" alt="">
                            </div>
                            <div class="name">{{ item.user_name }}</div>
                        </div>
                    </div>
                </div>
                <!-- 如果没有信息 -->
                <div v-else class="background">
                    <div class="background_img"><img src="../../assets/img/background.png" alt=""></div>
                    <div class="background_text">当前页面空空如也</div>

                </div>
                <!-- 班级选择器 -->
                <div>
                    <van-popup v-model="showPicker" position="bottom" get-container="body">
                        <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
                    </van-popup>
                </div>
                <div>
                </div>
                <div style="height: 45px;"></div>
                <div class="bottom">
                    <div class="bottom_box">
                        <van-icon @click="getUp" class="" name="arrow-left" />
                        <van-icon class="icon" name="arrow" />
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
import { NoticeClasses, NoticeIndex } from "../../api/notice/notice";
export default {
    data() {
        return {
            list: [{
                text: '全部'
            }, {
                text: '我发布的'
            }, {
                text: '定时发送'
            }],
            indexs: 0,
            columns: [
                { text: '查看全部', id: '' }
            ],
            value: "全部班级",
            showPicker: false,
            data: [
            ],
            classes_id: '',
            page: 1,
            listDate: [],
            isLoading: false,//下拉加载
            loading: false,
            finished: false,

        };
    },
    created() {
        this.getClass()
        this.getList()
    },
    methods: {
        getChange(e) {
            this.indexs = e
            this.finished = false
            this.getrefData()
        },
        getrefData() {
            NoticeIndex({ type: this.indexs, classes_id: this.classes_id, page: this.page, limit: '10' }).then((res) => {
                if (this.page == '1') {
                    // 下刷新
                    this.listDate = res.data.rows
                } else {
                    // 上拉加载
                    this.loading = false;
                    this.listDate = [...this.listDate, ...res.data.rows]; // 上一页数据跟新加载的数据做合并
                    // 判断数据是否加载完毕
                    if (res.data.rows.length < 1) {
                        // 将finished设置为true,表示所有数据加载完毕
                        this.$toast('暂无更多数据');
                        this.finished = true;

                    }
                }

            })
        },
        getList() {
            NoticeIndex({ type: 0, classes_id: this.classes_id, page: this.page, limit: '10' }).then((res) => {
                this.listDate = res.data.rows
            })
        },
        // 选择器
        getSelect() {
            this.showPicker = true
        },
        onSelect(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.showPicker = false;
            Toast(item.name);
        },
        onConfirm(value, index) {
            // 选择班级
            console.log(value);
            this.value = value.text
            NoticeIndex({ type: this.indexs, classes_id: value.id, page: '1', limit: '10' }).then((res) => {
                this.listDate = res.data.rows
            })
            this.showPicker = false;
        },
        // 详情
        getDetail(id) {
            this.$router.push({ path: "/detail", query: { id } });
        },
        // 返回上一页
        getUp() {
        },
        // 发布入口
        getPublish() {
            this.$router.push("/publish");
        },
        getParents() {
            this.$router.push('/teacher_index')
        },
        // 获取班级列表
        getClass() {
            NoticeClasses().then((res) => {
                console.log(res);
                if (res.code == 200) {
                    res.data.map(item => {
                        console.log(item);
                        this.columns.push({ text: item.name, id: item.id })
                    })
                }
            })
        },
        // 上拉加载
        onRefresh() {
            setTimeout(() => {
                this.$toast('刷新成功');
                this.isLoading = false;
                this.page = 1
                this.getrefData()
            }, 500);
        },
        // 上拉加载    
        onLoad() {
            this.loading = true
            this.page++
            setTimeout(() => {
                this.getrefData()
            }, 500);
        },
    },
    mounted() {
    },
    beforeDestroy() {
    },
};
</script>

<style lang="less" scoped>
.flexd {
    position: fixed;
    background-color: #fafafa;
    width: 100%;
}

.flex-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.backcolor {
    color: #ffffff;
    width: 52px;
    height: 24px;
    border-radius: 12px;
    background-color: #AFAFAF;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 12px;

    }
}

.flex-items {
    display: flex;
    align-items: center;
    padding: 15px 15px;

    .box {
        width: 70px;
        height: 35px;
        background-color: #efefef;
        text-align: center;
        line-height: 35px;
        color: #afafaf;
        margin-right: 15px;
        border-radius: 8px;
        font-size: 12px;
    }

    .boxs {
        width: 70px;
        height: 35px;
        background-color: #6990ff;
        text-align: center;
        line-height: 35px;
        color: #ffffff;
        margin-right: 15px;
        border-radius: 8px;
        font-size: 12px;
    }

    .allClasss {
        color: #afafaf;
        font-size: 12px;
        margin-left: 5px;
    }

    .title {
        color: #afafaf;
        font-size: 12px;
    }
}

.select {
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #afafaf;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.contain {
    padding: 3vw;
}

.bottom {
    width: 100%;
    text-align: center;

}

.contain-box {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: 0 0 5px #e8e8e8;
    margin-top: 2vw;
    box-sizing: border-box;
    padding: 1vw 3vw 3vw 0vw;
}

.display {
    display: flex;
    align-items: center;
}

.margin_top {
    padding-top: 10px;

    .left {
        height: 20px;
        width: 5px;
        background-color: #6990ff;
    }

    .text {
        margin-left: 10px;
        width: 302px;
        height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
    }
}

.time_num {
    padding: 10px 15px;
    justify-content: space-between;

    .title {
        font-size: 12px;
        color: #8c8c8c;

    }

    .time {
        font-size: 12px;
        margin-top: 1px;
    }
}

.avater {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 15px;

    img {
        width: 100%;
    }
}

.name {
    margin-left: 10px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    width: 25vw;
}

.backphoto {
    width: 80px;
    height: 24px;

    img {
        width: 100%;
    }
}

.bottom {
    position: fixed;
    bottom: 0;
    height: 49px;
    background: #F7F7F7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom_box {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.icon {
    color: #999999;
    margin-left: 20px;
}

.background {
    display: flex;
    justify-content: center;
    align-items: center;

    .background_img {
        img {
            width: 234px;
            height: 70px;
        }

    }
}

.background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .background_img {
        width: 234px;
        height: 234px;

        img {
            width: 234px;
            height: 234px;
        }
    }

    .background_text {
        width: 102px;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
}
</style>
  